import {enumAttributeDataModel} from 'shared/models/attributeComponent.model';
import {slimAttributeResponseModel} from 'shared/models/attribute.model';
import {ValueGetterParams} from 'ag-grid-community';

const generalInformationValueGetter = (params: ValueGetterParams, promotionTypesDict: slimAttributeResponseModel, themeSeasonDict: slimAttributeResponseModel, countryThemedWeeksDic: slimAttributeResponseModel): string => {
    let derivedData: string = '';
    if (!params.data.promotionTypes?.literals?.length && params.data.derivedPromotionTypes?.literals?.length) {
        derivedData += (promotionTypesDict?.options.filter((o) => params?.data?.derivedPromotionTypes?.literals?.some((literal: enumAttributeDataModel) => literal.literalId === o.id)).map(o => o.name));
    }
    if (!params.data.themeSeasons?.literals?.length && params.data.derivedThemeSeasons?.literals?.length) {
        derivedData += (themeSeasonDict?.options.filter((o) => params?.data?.derivedThemeSeasons?.literals?.some((literal: enumAttributeDataModel) => literal.literalId === o.id)).map(o => o.name));
    }
    if (!params.data.countryThemedWeeks?.literals?.length && params.data.derivedThemedWeeks?.literals?.length) {
        derivedData += (countryThemedWeeksDic?.options.filter((o) => params?.data?.derivedThemedWeeks?.literals?.some((literal: enumAttributeDataModel) => literal.literalId === o.id)).map(o => o.name));
    }
    const generalInformation: string = [
        ...[derivedData],
        ...[params.data.generalInformation.promotionTypes && params.data.generalInformation.promotionTypes],
        ...[params.data.generalInformation.themeSeasons && params.data.generalInformation.themeSeasons],
        ...[params.data.generalInformation.countryThemedWeeks && params.data.generalInformation.countryThemedWeeks],
        ...[params.data.generalInformation.qualitySeals && params.data.generalInformation.qualitySeals],
    ]
        .flat()
        .filter((attr: string) => attr && attr !== "" && !attr?.startsWith('<<') && !attr?.endsWith('>>'))
        .join(' ');
    return generalInformation;
}

export default generalInformationValueGetter;