/* eslint-disable react-hooks/exhaustive-deps */
import './ProductSearch.scss';

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {
    Divider,
    Button,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    InputAdornment,
    TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
    API_PRODUCT_DATA_BPCOS_SEARCH,
    API_PRODUCT_PROPOSAL,
    API_PRODUCT_SEARCH,
    API_STATUSES
} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import {responseValidation} from 'utils/responseValidation';
import {PROMOTION_TYPES} from 'shared/models/promotion.model';
import {IRootState} from 'shared/reducers';
import {handyLiterals} from 'shared/handyLiterals';
import {
    productSearchResult,
    productSearchResultResponse,
    productSearchResultUI
} from 'shared/models/product.model';
import {ProductSearchCard} from './ProductSearchHelpers';
import {groupResults} from './utils';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ToggleTemplates from 'components/Controls/ToggleTemplates';

const minSearchLen = 1;

interface ProductSearchProps {
    countryId: string,
    frameId?: string,
    isProductSuggestionBtn?: boolean,
    onProductClick: Function,
    onToggleTemplateChange?: (value: boolean) => void,
    showToogleTemplate?: boolean,
    selectedItemsIds: string[], //ids of already selected products or bpcos
    templatesExcluded?: boolean
}

type productSearchQueryParams = {
    countryId: string,
    lang: string,
    search: string,
    templatesExcluded?: boolean
}

const ProductSearch = ({countryId, frameId, isProductSuggestionBtn, onProductClick, onToggleTemplateChange, showToogleTemplate, selectedItemsIds, templatesExcluded}: ProductSearchProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryStr, setQueryStr] = useState<string>('');
    const [groupedResults, setGroupedResults] = useState<productSearchResultUI[][]>([]);
    const [searchBPCOs, setSearchBPCOs] = useState<boolean>(false);
    const [dbncdQueryStr, setDbncdQueryStr] = useDebounce(queryStr, 500);
    const [status, setStatus] = useState(API_STATUSES.NEW);
    const [searchWords, setSearchWords] = useState<string[]>([]);

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const cancelToken = useRef(null);

    const [literalsTranslations, setLiteralsTranslations] = useState<string[]>([]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown,true)
        const literalsTranslationsList:string[] = [];
        literalsTranslationsList.push(handyLiterals.unidentified, handyLiterals.gpcUnidentified, handyLiterals.gpcUnclassified, handyLiterals.unclassified);
        setLiteralsTranslations(literalsTranslationsList);
        return () => {
            document.removeEventListener('keydown', handleKeyDown,true)
        }
    },[]);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            cleanResults();
            handleSearchInputChange('');
        }
    };

    const transformResponse = (data: productSearchResultResponse, isBPCO:boolean) => {
        const promotionObjectType = isBPCO ? PROMOTION_TYPES.BPCO : PROMOTION_TYPES.PRODUCT;
        const extendedProductsList = data.map((item) => {
            const newItem: productSearchResultUI = {...item, promotionObjectType};
            return newItem;
        })
        setGroupedResults(groupResults(extendedProductsList));
    };

    const getData = (origToken) => {
        if (origToken) {
            origToken();
        }
        setStatus(API_STATUSES.PENDING);
        const url = searchBPCOs ? API_PRODUCT_DATA_BPCOS_SEARCH : API_PRODUCT_SEARCH;
        const params: productSearchQueryParams = {
            countryId,
            lang: selectedLanguage,
            search: dbncdQueryStr
        };
        if (!searchBPCOs && templatesExcluded) {
            params.templatesExcluded = true;
        }
        axios.get<productSearchResultResponse>(url, {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            ),
            params
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    transformResponse(resp.data, searchBPCOs);
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e)
                }
            })
            .finally(() => setStatus(API_STATUSES.IDLE));
    };

    const getSuggestedPromotions = () => {
        setIsLoading(true);
        handleSearchInputChange('');
        axios.get<productSearchResultResponse>(API_PRODUCT_PROPOSAL(frameId, countryId, selectedLanguage), {
            cancelToken: new axios.CancelToken(cancel => (cancelToken.current = cancel))
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    if (resp.data.length) transformResponse(resp.data, searchBPCOs);
                    else enqueueSnackbar(translate({id: 'frameDescription.noProductSugForFrame'}), {variant: 'error', persist: false});
                } else enqueueSnackbar(translate({id: 'a.error2'}), {variant: 'error', persist: false});
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(translate({id: 'a.error2'}), {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (dbncdQueryStr.length > minSearchLen) {
            getData(cancelToken.current);
        }
        setSearchWords(dbncdQueryStr.split(' '));
    }, [dbncdQueryStr]);

    useEffect(() => {
        if (status !== API_STATUSES.IDLE) {
            cleanResults();
        }
    }, [status]);

    useEffect(() => {
        if (dbncdQueryStr.length > minSearchLen) {
            getData(cancelToken.current);
        }
    }, [searchBPCOs]);

    useEffect(() => {
        if (templatesExcluded !== undefined && dbncdQueryStr.length > minSearchLen) {
            getData(cancelToken.current);
        }
    }, [templatesExcluded]);

    const handleSearchInputChange = (val: string) => {
        setQueryStr(val);
        setDbncdQueryStr(val)
    };
    
    const handleProductClick = (e, data:productSearchResult) => {
        onProductClick(data);
        if (!e.ctrlKey && !e.metaKey) cleanResults();
        handleSearchInputChange('');
    };

    const handleEnterPressed = (code: string) => {
        if ( code === 'Enter' && dbncdQueryStr.length > minSearchLen) {
            getData(cancelToken.current);
        }
    };

    const cleanResults = () => setGroupedResults([]);

    const results = () => groupedResults.map((group) => (
        <div className="productSearchCardContainer" key={group[0].id}>
            {group.map((item) => <ProductSearchCard key={item.id}
                                                    data={item}
                                                    highlight={selectedItemsIds.includes(item.id)}
                                                    onClick={(e, data) => handleProductClick(e, data)}
                                                    searchWords={searchWords}
                                                    literalsTranslations={literalsTranslations} />)}
        </div>)
    );

    return (
        <div className="productSearchRoot">
            <LoadingOverlay show={isLoading}/>
            <div className="searchInput">
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           onKeyDown={(e) => handleEnterPressed(e.key)}
                           label={translate({id: 'a.search'})}
                           value={queryStr}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">
                                   {status === API_STATUSES.PENDING ? <CircularProgress size={24}/>: <SearchIcon color="secondary"/> }
                               </InputAdornment>
                           }}
                           id='productSearchField'
                />
                <FormControlLabel className="apiSwitch"
                                  control={<Checkbox tabIndex={-1}
                                                     value={searchBPCOs}
                                                     onChange={(e) => setSearchBPCOs(e.target.checked)}/>}
                                                     disabled={status === API_STATUSES.PENDING}
                                                     label={translate({id: 'productSearch.toggleSearch'})}
                />
                {showToogleTemplate && <ToggleTemplates value={!templatesExcluded} onChange={onToggleTemplateChange}/>}
                {isProductSuggestionBtn && <>
                    <Divider orientation="vertical" className="dividerV"/>
                        <div className="productSuggContainer">
                            <Button onClick={getSuggestedPromotions} 
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    disabled={status === API_STATUSES.PENDING || !(frameId && countryId && selectedLanguage)}>
                                {translate({id: 'frameDescription.productSugTitle'})}
                            </Button>
                </div></>}
            </div>
            {groupedResults.length > 0 &&
            <ClickAwayListener onClickAway={() => cleanResults()}>
                <div className="searchResultsContainer">
                    <div className="topBar">
                        <FormattedMessage id="productSearch.results"/>
                    </div>
                    {results()}
                </div>
            </ClickAwayListener>}
        </div>
    );
};

export default ProductSearch;