/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Drawer, IconButton} from '@mui/material';
import {API_PROMOTIONS_SUGGESTIONS} from 'config/api/constants';
import {promotionSuggestionsForFrame, promotionSuggestionsResponseModel} from 'shared/models/promotion.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import PromotionsSuggestion from './PromotionsSuggestion';
import SearchIcon from '@mui/icons-material/Search';
import {SearchOff} from "@mui/icons-material";

interface PromotionsSuggestionsProps {
    countryId: string
    frameId: string,
    onClick: (promotionsForFrame: promotionSuggestionsForFrame, suggestionPosition: number, forceEraseAndOverwrite: boolean) => void,
    onClose: () => void,
    forceOpen: boolean,
    initiallyOpen?: boolean,
    isZoomEnabled: boolean,
    toggleZoom: () => void,
}

const PromotionsSuggestions = (props: PromotionsSuggestionsProps) => {
    const {countryId, frameId, onClick, onClose, forceOpen, initiallyOpen = false, isZoomEnabled, toggleZoom} = props;
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldUpdate, setShouldUpdate] = useState<number>(0);
    const [suggestions, setSuggestions] = useState<promotionSuggestionsResponseModel>([]);
    const [currentSuggestions, setCurrentSuggestions] = useState<promotionSuggestionsResponseModel>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const iterationNumber: number = 3;


    const cancelToken = useRef(null);

    const cancelQuery = () => {
        if (cancelToken.current) cancelToken.current();
    };

    useEffect(() => {
        return cancelQuery();
    }, []);

    const getProductSuggestions = (frameId: string, countryId: string) => {
        cancelQuery();
        setIsLoading(true);
        setSuggestions([]);
        setCurrentSuggestions([]);
        setCurrentIndex(0);
        setOpen(true);
        axios
            .get<promotionSuggestionsResponseModel>(API_PROMOTIONS_SUGGESTIONS(frameId, selectedLanguage, countryId), {
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
            .then((response) => {
                if (responseValidation(response.data) && response.data?.length) {
                    setCurrentSuggestions(response.data.slice(0, iterationNumber));
                    setSuggestions(response.data);
                } else {
                    const messageId: string = response.status === 202 ? 'frameDescription.framePrepNotFinished' : 'frameDescription.noProposalsForFrame';
                    setOpen(false);
                    enqueueSnackbar(`${translate({id: messageId})}`,{variant: 'info', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    setOpen(false);
                    enqueueSnackbar(`${translate({id: 'frameDescription.fetchPromoSugErr'})}`,{variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (frameId && countryId && initiallyOpen) {
            getProductSuggestions(frameId, countryId);
        } else {
            cancelQuery();
        }
    }, [frameId, countryId, initiallyOpen]);

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
            if (!(suggestions.length)) {
                getProductSuggestions(frameId, countryId);
            }
        }
    }, [forceOpen]);

    useEffect(() => {
        if (shouldUpdate && frameId && countryId) {
            getProductSuggestions(frameId, countryId);
        }
    }, [shouldUpdate]);

    const handleSuggestionClick = (frameId: string, forceEraseAndOverwrite: boolean) => {
        setOpen(false);
        const clickedSuggestion: promotionSuggestionsForFrame = currentSuggestions.find((item) => item.frameId === frameId);
        const suggestionPosition: number = currentSuggestions.indexOf(clickedSuggestion);
        onClick(clickedSuggestion, currentIndex + suggestionPosition + 1, forceEraseAndOverwrite);

    }

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    const handleNextSuggestions = () => {
        const newIndex: number = currentIndex + iterationNumber;
        if (newIndex < suggestions.length) {
            setCurrentIndex(newIndex);
            setCurrentSuggestions(suggestions.slice(newIndex, newIndex + iterationNumber));
        }
    }

    const handlePrevSuggestions = () => {
        const newIndex: number = currentIndex - iterationNumber;
        if (newIndex < suggestions.length) {
            setCurrentIndex(newIndex);
            setCurrentSuggestions(suggestions.slice(newIndex, newIndex + iterationNumber));
        }
    }


    return (
        <Drawer className="frameDescriptionDrawerRoot"
                anchor="right"
                hideBackdrop
                open={open}
                onClose={handleClose}
        >
            <LoadingOverlay show={isLoading}/>
            <div className="_directionRow">
                {currentSuggestions
                    .map((suggestion) => <PromotionsSuggestion key={suggestion.frameId}
                                                               data={suggestion}
                                                               isZoomEnabled={isZoomEnabled}
                                                               onClick={(frameId, forceEraseAndOverwrite) => handleSuggestionClick(frameId, forceEraseAndOverwrite)}
                                                               refetch={() => setShouldUpdate(shouldUpdate + 1)}/>)
                }
            </div>
            <Footer hideNavigationMenu
                    actionsLeft={
                        <>
                            <IconButton color="primary" onClick={toggleZoom}>
                                {isZoomEnabled ? <SearchIcon/> : <SearchOff/>}
                            </IconButton>
                        </>
                    }
                    actionsRight={
                        <>
                            <Button variant="contained" onClick={handlePrevSuggestions} disabled={isLoading || currentIndex === 0}>
                                {translate({id: 'a.prev'})}
                            </Button>
                            <Button variant="contained" onClick={handleNextSuggestions} disabled={isLoading || currentIndex+iterationNumber >= suggestions.length}>
                                {translate({id: 'a.next'})}
                            </Button>
                            <ButtonClose onClick={handleClose}/>
                        </>
                    }
            />
        </Drawer>
    );
};

export default PromotionsSuggestions;